@import 'Assets/styles/base';
@import 'Assets/styles/breakpoints';

.accord {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #3352CB;
  padding: 18px;
  @include desktop {
    padding: 30px 0;
    width: 80%;
  }

  .title {
    font-size: 13px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    display: flex;
    text-transform: uppercase;
    @include desktop {
      font-size: 23px;
    }
    span {
      flex: 1;
    }

    .cross {
      height: 9px;
      width: 9px;
      position: relative;
      transition:height 5.5s ease-out;
      @include desktop {
        margin: 0 40px;
        height: 28px;
        width: 28px;
      }
      @include tablet {
        height: 13px;
        width: 13px;
      }
      .top {
        width: 1px;
        height: 100%;
        background: #3352CB;
        position: absolute;
        top: 0;
        left: 6px;
        @include desktop {
          left: 14px;
          width: 1px;
        }
      }
      .down {
        height: 1px;
        width: 100%;
        background: #3352CB;
        position: absolute;
        left: 0;
        top: 6px;
        @include desktop {
          top: 14px;
          height: 1px;
        }
      }
    }
  }

  .body {
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    padding: 30px 40px;
    background-color: #F5F9FC;
    line-height: 20px;
    margin: 35px 0;
    @include desktop {
      margin-right: 95px;
    }
  }
}
