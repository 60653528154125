@import './breakpoints';

$break-point-smobile: 320px;
$break-point-mobile: 425px;
$break-point-exmobile: 426px;
$break-point-tablet: 768px;
$break-point-xtablet: 769px;
$break-point-miniLaptop: 1024px;

%reset-style {
  margin: 0;
}

@mixin mobile() {
  @media only screen and (min-width: $break-point-smobile) and (max-width: $break-point-mobile) {
    @content;
  }
}
@mixin tablet() {
  @media only screen and (min-width: $break-point-exmobile) and (max-width: $break-point-tablet) {
    @content;
  }
}

@mixin miniLaptop() {
  @media only screen and (min-width: $break-point-xtablet) and (max-width: $break-point-miniLaptop) {
    @content;
  }
}

%container-style {
  max-width: 1200px;
  margin: 0 auto;
}

%home-button-style {
  background: white;
  border: 1px solid #d2a940;
  font-family: 'Lato';
  padding: 3% 2%;
  min-width: 130px;
  font-size: 12px;

  @include desktop {
    min-width: 150px;
    font-size: 15px;
  }
}

@mixin container {
  max-width: 1200px;
  margin: 0 auto;

  @include largeDesktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin padding {
  padding: 0 10%;

  @include desktop {
    padding: 0 5%;
  }

  @include largeDesktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin minContainer {
  max-width: 85vw;

  @media screen and (min-width: 800px) and (max-width: 1150px) {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  @media screen and (min-width: 800px) {
    max-width: 1100px;
  }

  @media screen and (min-width: 1300px) {
    max-width: 1200px;
  }
}
