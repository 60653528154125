@import '../../Assets/styles/base';
.card {
  padding: 69px 40px 40px 40px;
  flex: 1;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border: 1px solid #3eb33733;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 27px;
    line-height: 40px;
    font-weight: 600;
    color: #3352cb;
    padding: 10px 25px;
    background-color: #fff;
    position: absolute;
    top: -30px;
    left: 20px;
  }

  .children {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex: 1;

    h2 {
      color: #3352cb;
    }
    button {
      background: #3352cb;
      padding: 15px 34px;
      border-radius: 3px;
      color: #fff;
      border: none;
      margin: 0 5px;
    }
  }
}

.vertical {
  min-height: 454px;
}
