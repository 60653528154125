$break-point-mobile: 480px;
$break-point-tablet: 768px;

@mixin mobile() {
  @media (max-width: $break-point-mobile) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 481px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin largeDesktop {
  @media (min-width: 1300px) {
    @content;
  }
}
