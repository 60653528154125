@import-normalize; /* bring in normalize.css styles */

// @font-face {
//   font-family: 'Circular Book';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('./Assets/fonts/Circular-Book/Circular-Book.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Lato';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('./Assets/fonts/lato/Lato-Light.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Lato';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('./Assets/fonts/lato/Lato-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Lato';
//   font-weight: 700;
//   font-display: swap;
//   src: url('./Assets/fonts/lato/Lato-Bold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Butler';
//   font-weight: 200;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_Ultra_Light.otf')
//     format('opentype');
// }
@font-face {
  font-family: 'Glacial Indifference';
  font-weight: bold;
  font-display: swap;
  src: url('./Assets/fonts/glacial-indifference/GlacialIndifference-Bold.otf')
    format('opentype');
}
@font-face {
  font-family: 'Glacial Indifference';
  font-weight: normal;
  font-display: swap;
  src: url('./Assets/fonts/glacial-indifference/GlacialIndifference-Regular.otf')
    format('opentype');
}
@font-face {
  font-family: 'Object Sans';
  font-weight: 900;
  font-display: swap;
  src: url('./Assets/fonts/Object-Sans/ObjectSans-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'Object Sans';
  font-weight: normal;
  font-display: swap;
  src: url('./Assets/fonts/Object-Sans/ObjectSans-Regular.otf')
    format('opentype');
}

// @font-face {
//   font-family: 'Butler';
//   font-weight: 300;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_Light.otf') format('opentype');
// }

// @font-face {
//   font-family: 'Butler';
//   font-weight: 400;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_Regular.otf') format('opentype');
// }

// @font-face {
//   font-family: 'Butler';
//   font-weight: 500;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_Medium.otf') format('opentype');
// }

// @font-face {
//   font-family: 'Butler';
//   font-weight: 700;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_Bold.otf') format('opentype');
// }

// @font-face {
//   font-family: 'Butler';
//   font-weight: 800;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_ExtraBold.otf')
//     format('opentype');
// }

// @font-face {
//   font-family: 'Butler';
//   font-weight: 900;
//   font-display: swap;
//   src: url('./Assets/fonts/Butler/Butler/Butler_Black.otf') format('opentype');
// }

// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('./Assets/fonts/poppins/Poppins-Regular.otf') format('opentype');
// }

* {
  box-sizing: border-box;
  margin: 0;
}

// html {
//   overflow: hidden;
// }

body {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: 'Object Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
