.input-range__label--min,
.input-range__label--max {
  display: none !important;
}

.input-range__label-container {
  font-size: 10px !important;
  color: #000;
}
.input-range__track--active {
  background-color: #3eb337 !important;
}

.input-range__slider {
  background: #3352cb !important;
  border: none !important;
}

.input-range__track {
  height: 2px;
}
