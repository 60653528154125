@import 'Assets/styles/base';
@import 'Assets/styles/breakpoints';

.partition {
  width: 100%;
  display: flex;
  height: auto;
  margin: 0;
  flex-direction: column;
  padding: 24px;

  @include desktop {
    flex-direction: row;
    padding: 0;
  }

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
      min-height: 300px;
      width: 270px;
    }
    .text {
      font-size: 31px;
      font-family: 'Lato', sans-serif;
      width: 150px;
      padding: 20px 0;
      color: #3352CB;
      font-weight: bolder;
      @include desktop {
        width: auto;
        padding: 50px;
        padding-top: 80px;
        flex: 1;
      }
      @include tablet {
        font-size: 20px;
      }
      @include mobile {
        font-size: 15px;
      }
    }
    .side {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      @include desktop {
        flex-direction: column;
        height: 100%;
      }
      .top,
      .bottom,
      .mid {
        height: 1px;
        background: #3EB337;
        min-width: 50px;
        @include desktop {
          min-height: 50px;
          width: 1px;
          min-width: 1px;
        }
      }
      .top {
        min-width: 0;
        @include desktop {
          width: 1;
        }
      }
      .mid {
        height: 8px;
        width: 50%;
        @include desktop {
          width: 8px;
          height: 150px;
        }
      }
      .bottom {
        flex: 1;
      }
    }
  }
  .right {
    flex: 1;
    padding: 24px 0;
    @include desktop {
      padding: 50px;
    }
  }
}
